import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthUserService } from '../data-access/auth-user.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authUserService = inject(AuthUserService);

  if (!authUserService.isAuthenticated()) {
    return authUserService.handleUnauthenticated();
  }

  return authUserService.isAuthenticated();
};
