import { Injectable } from '@angular/core';
import { BASE_FONT_SIZE, BASE_FONT_VARIABLE } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class FontSizeService {
  private currentFontSize: number = BASE_FONT_SIZE;
  private baseFontVariable: string = BASE_FONT_VARIABLE;

  setFontSize(fontSize: number) {
    this.currentFontSize = fontSize;
    document.documentElement.style.setProperty(
      this.baseFontVariable,
      `${this.currentFontSize}px`,
    );
  }

  getFontSize(): number {
    return this.currentFontSize;
  }

  changeFontSize(fontSize: number) {
    this.setFontSize(fontSize);
  }
}
