import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthUserService } from '../../../auth/data-access/auth-user.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authUserService = inject(AuthUserService);

  const token = authUserService.token();

  if (token)
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

  return next(req);
};
