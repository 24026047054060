import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInOut = (targetOpacity: number) => {
  return trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate('500ms', style({ opacity: targetOpacity })),
    ]),
    transition('* => void', [animate('500ms', style({ opacity: 0 }))]),
  ]);
};
